<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100" id="navbar-nav">
      <li class="menu-title">
        <span> Navigation </span>
      </li>
      <li class="nav-item">
        <router-link class="nav-link menu-link" to="/">
          <i class="ri-dashboard-fill"></i>
          <span> Dashboard </span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link menu-link" to="/allocations">
          <i class="ri-sort-desc"></i>
          <span> Allocations </span>
        </router-link>
      </li>
      <li class="nav-item">
        <a
          href="#sidebarPatients"
          class="nav-link menu-link"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="sidebarPatients"
        >
          <i class="mdi mdi-human-male-female-child"></i>
          <span data-key="t-settings"> Manage Patients</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarPatients">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link
                to="/patients/find"
                class="nav-link"
                data-key="t-patient-find"
              >
                Find Patient
              </router-link>

              <router-link
                to="/patients/create"
                class="nav-link"
                data-key="t-patient-create"
              >
                Add Patient
              </router-link>
              <router-link
                to="/patients"
                class="nav-link"
                data-key="t-patient-find"
              >
                Patients List
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <router-link class="nav-link menu-link" to="/notifications">
          <i class="bx bx-bell"></i>
          <span> Notifications</span>
        </router-link>
      </li>
      <li class="menu-title">
        <hr>
      </li>
      <li class="nav-item">
        <a
          href="#sidebarSettings"
          class="nav-link menu-link"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="sidebarSettings"
        >
          <i class="ri-settings-2-line"></i>
          <span data-key="t-settings"> Settings</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarSettings">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link
                to="/account"
                class="nav-link"
                data-key="t-profile"
              >
                Account
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";

export default {
  components: { SimpleBar },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 1)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 1) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    }, 0);
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
            document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                currentPosition + 300;
        }
      }, 300);
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
};
</script>


